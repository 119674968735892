body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #363636;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
	color: #a16d5d;
}

.full {
	width: 100%;
}

.circle {
	border-radius: 50%;
}

.centerAlign {
	text-align: center;
}

.textVerticalCenter {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}


.App {
	background: white;
}

/* Social login button */
.socialLogin {
	width: 100%;
	box-sizing: border-box;
	font-size: 1.1em;
	padding: 15px 0px;
	color: white;
	background-color: gray;
	border: none;
	cursor: pointer;
}

.socialLogin.google {
	background-color: #CF4332;
}
.socialLogin.facebook {
	background-color: #3C66C4;
}

input, select, textarea {
	font-size: 1.0em;
	width: 100%;
	margin: 10px 0px;
	padding: 7px 10px;
	box-sizing: border-box;
	border: 0px solid transparent;
	border-bottom: 1px solid #ccc;
}
textarea {
	border: 1px solid #ccc;
}
input:focus, select:focus {
	outline: none;
	border-bottom: 1px solid #888;
}
input:not([value=""]):invalid, select:invalid {
	border-bottom: 1px solid #f44336;
}

input[type="submit"], button.btn {
	width: 100%;
	color: white;
	background: #a16d5d;
	cursor: pointer;
	padding: 10px;
	margin: 15px 0px;
	border: 1px solid #a16d5d;
	border-radius: 5px;
}
input[type="submit"]:hover, button.btn:hover {
	background:#996758;
}
input:disabled, button:disabled, select:disabled, textarea:disabled {
	cursor: not-allowed;
	background: #eee;
}
input[type="range"]
{
	padding: 0px;
}

.btn-second {
	border: 1px solid rgb(204, 204, 204) !important;
    background: transparent !important;
    color: #333 !important;
    border-radius: 5px !important;
}
.btn-second:hover {
	background: #eaeaea !important;
	cursor: pointer !important;
}

.error {
	background: rgb(244, 67, 54);
    color: white;
    width: 100%;
    display: block;
	padding: 5px;
	box-sizing: border-box;
}



.hoverZoomOut, .hoverZoomIn {
	transition: transform 0.2s ease-in-out;
}
.hoverZoomOut:hover{
	transform: scale(0.9);
}

.hoverZoomIn:hover{
	transform: scale(1.1);
}


/* FB login */
.fb-login {
	display: block;
	text-decoration: none;
	color: #1D1F23;
	background: #E4E6EB;
	text-align: center;
	padding: 10px;
	margin: 10px;
	border-radius: 50px;
	font-size: 1.1rem;
	transition: all 0.2s ease-in-out;
}

.fb-login:hover, .fb-login:focus {
	outline: none;
	color: white;
	background: #1778F2;
}