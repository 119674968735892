footer {
	padding: 25px;
	background: #363636;
	margin-top: 10px;
}

footer label {
	display: block;
	color: white;
	font-weight: bold;
	margin: 15px 0px;
}

footer a {
	display: block;
	text-decoration: none;
	padding: 5px 0px;
	color: #aeaeae;
}
footer a:hover {
	color: #ffffff;
	transition: color 0.3s;
}

footer img {
	width: 100%;
	pointer-events: none;
}