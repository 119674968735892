.car {
	animation: carDriving 2s ease-in-out infinite;
}

@keyframes carDriving {
	0%, 100% {
		transform: scale(1,1) translateY(0%);
	}
	50% {
		transform: scale(1.1,0.9) translateY(10%);
	}
}