.quickbar .dropdown {
	display: block;
	float: right;
	overflow: visible !important;
}
.quickbar .dropdown-wrap {
	display: none;
	position: absolute;
	background: transparent;
	padding: 1.5em 0px 0px 0px;
	width: fit-content;
}

.quickbar .dropdown:hover .dropdown-wrap{
	display: block;
	z-index: 1;
}

.quickbar .dropdown-content {
	background: white;
	padding: 10px 0px;
	border: 1px solid #eaeaea;
	border-radius: 5px;
}
.quickbar .dropdown-content::before {
	content: '';
	display: block;
	width: 1em;
	height: 1em;
	background: white;
	border-top: 1px solid #eaeaea;
	border-left: 1px solid #eaeaea;
	border-right: none;
	border-bottom: none;
	position: absolute;
	transform: translate(1em,calc(-10px - 50%)) rotate(45deg);
	border-radius: 5px;
}

.quickbar .dropdown-content .btn{
	display: block;
	padding: 10px;
	text-decoration: none;
	color: #333;
}
.quickbar .dropdown-content .btn:hover{
	background: #eaeaea;
	cursor: pointer;
}

.quickbar .shaking {
	animation: shaking 3s infinite;
}

@keyframes shaking {
	0%, 45%, 80% {
		transform: translateX(0%) translateY(50%) scale(1);
	}

	50%, 60%, 70% {
		transform: translateX(-10%) translateY(50%) rotate(10deg) scale(1.3);
	}
	55%, 65%, 75% {
		transform: translateX(10%) translateY(50%) rotate(-10deg) scale(1.3);
	}
}