.sell-item {
	color: black;
	text-decoration: none;
}
.sell-item .row
{
	transition: background-color 0.3s;
}
.sell-item:hover .row {
	background-color: #eee;
}