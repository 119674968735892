/* Customize the label (the container) */
.checkbox {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	margin: 10px 0px;
  }
  
  /* Hide the browser's default checkbox */
  .checkbox input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
  }
  
  /* Create a custom checkbox */
  .checkbox .checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	background-color: #eee;
	border: 1px solid #ccc;
  }
  
  /* On mouse-over, add a grey background color */
  .checkbox:hover input ~ .checkmark {
	background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
  .checkbox input:checked ~ .checkmark {
	background-color: #a16d5d;
	border: 1px solid #a16d5d;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkbox .checkmark:after {
	content: "";
	position: absolute;
	display: none;
  }
  
  /* Show the checkmark when checked */
  .checkbox input:checked ~ .checkmark:after {
	display: block;
  }
  
  /* Style the checkmark/indicator */
  .checkbox .checkmark:after {
	left: 6px;
	top: 2px;
	width: 5px;
	height: 10px;
	border: solid white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
  }