.editor .btn {
	padding: 10px;
	cursor: pointer;
	box-shadow: 0px 0px 0px #919191;
	transition: box-shadow 0.2s, background 0.2s, color 0.2s;
	user-select: none;
	border-radius: 5px;
}

.editor div.btn:hover:not(.active) {
	background: #eaeaea;
}
.editor .btn:focus {
	outline: none;
}

.editor .btn.active {
	background: #a16d5d;
	color: white;
}


.colors input[type=radio] {
	display: none;
}

.colors input[type=radio] ~ label {
	cursor: pointer;
	user-select: none;
	display: block;
	padding: 10px;
	margin: 10px;
	border: 2px solid white;
	color: transparent;
}
.colors input[type=radio]:checked ~ label {
	border: 2px dotted black;
}

.editor label {
	padding-left: 10px;
	margin-top: 10px;
	display: block;
}

.delete {
	opacity: 0;
	transform: translate(3px, 100%);
	transition: all 0.1s;
	pointer-events: none;
}

.hoverDelete:hover .delete {
	opacity: 1;
	transform: translate(3px,0%);
	pointer-events: all;
}

.imgedit img{
	transition: transform 0.2s ease-in-out;
}
.imgedit img:hover{
	transform: scale(0.9);
}