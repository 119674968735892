.loginBox {
	display: block;
	position: fixed;
	top: 0px;
	left: 0px;
	z-index: 1000;
	width: 100vw;
	height: 100vh;
	background: rgba(0,0,0,0.4);
	animation: loginBox 0.5s;
}
.loginBox .wrap {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	width: 30vw;
	min-height: 55vh;
	background: white;
	padding: 25px;
	box-sizing: border-box;
	box-shadow: 1px 1px 5px 0px #717171;
	border-radius: 5px;
	animation: loginBoxWrap 0.5s;
}

@media screen and (max-width: 1600px) {
	.loginBox .wrap {
		width: 50vw;
	}
}
@media screen and (max-width: 1000px) {
	.loginBox .wrap {
		width: 70vw;
	}
}
@media screen and (max-width: 553px) {
	.loginBox .wrap {
		width: 99vw;
		padding: 5px;
	}
}


.loginBox h1 {
	text-align: center;
}

.line div {
	width: 100%;
	height: 1px;
	background: #ccc;
	margin: 20px 0px;
	position: relative;
}

.loginBox .line p {
	text-align: center;
	font-size: 0.8em;
	color: #333;
	top: 50%;
	left: 50%;
	margin: 0px;
	transform: translate(-50%,calc(-50% - 2px));
	padding: 0px 10px;
	background: white;
	position: absolute;
	display: block;
}

@keyframes loginBox{
	0%{
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes loginBoxWrap {
	0% {
		transform: translate(-50%,-100%);
	}
	100% {
		transform: translate(-50%,-50%);
	}
}