.messageBanner {
	display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    margin: auto;
    text-align: center;
    -webkit-animation-duration: .5s;
    animation-duration: .5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-timing-function: ease-in-out;
	animation-timing-function: ease-in-out;
	transition: background-color 0.5s;
}

.messageBanner .messageContent {
	height: 40px;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
	transition: .3s ease-in-out;
	overflow: hidden;
	position: relative;
}

.messageBanner p {
	margin: 10px;
	position: absolute;
    top: 0;
    left: 0;
    right: 0;
}
.messageBanner p.on {
	animation: show 0.5s linear;
	opacity: 1;
}

.messageBanner p.off {
	animation: hide 0.5s linear;
	opacity: 0;
	pointer-events: none;
}

@keyframes show {
	0% {
		opacity: 1;
		transform: translateY(40px);
	}
	100% {
		opacity: 1;
		transform: translateY(0px);
	}
}

@keyframes hide {
	0% {
		opacity: 1;
		transform: translateY(0px);
	}
	100% {
		opacity: 1;
		transform: translateY(-40px);
	}
}