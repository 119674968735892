.ItemWidget {
	background: white;
	padding: 20px;
	box-shadow: 0 1px 2px 0 rgba(60,64,67,.3), 0 2px 6px 2px rgba(60,64,67,.15);
	margin-bottom: 10px;
}
.ItemWidget .like {
	margin-top: 16px;
}

.ItemWidget .tags {
	padding-top: 10px
}