.mobile-menu-wrap {
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.7);
	animation: wrapShow 0.3s;
}

.mobile-menu {
	position: fixed;
	background: white;
	width: 80%;
	height: 100%;
	top: 0px;
	right: 0px;
	animation: menuShow 0.3s;
	padding: 10px;
	box-sizing: border-box;
	overflow-x: hidden;
	overflow-y: scroll;
}

.mobile-menu .item {
	display: block;
	width: 100%;
	padding: 15px 10px;
	box-sizing: border-box;
	border-bottom: 1px solid #eaeaea;
}
.mobile-menu .item a {
	color: black;
	text-decoration: none;
	width: calc(50%);
	display: inline-block;
}

.mobile-menu .item .arrow {
	/*background: red;*/
	width: 1em;
	height: 1em;
	margin: 0px;
	float: right;
	transform: rotate(45deg);
	border-left: 1px solid #333;
	border-bottom: 1px solid #333;
	transition: transform 0.3s;
}
.mobile-menu .item:hover .arrow {
	transform: rotate(-45deg);
}

.mobile-menu .item .subitems {
	display: none;
}
.mobile-menu .item:hover .subitems {
	display: block;
}



.spin {
	animation: spin 0.5s;
}

@keyframes wrapShow {
	0%{
		opacity: 0;
	}
	100%{
		opacity: 1;
	}
}

@keyframes menuShow{
	0%{
		transform: translateX(100%);
	}
	100%{
		transform: translateX(0%);
	}
}

@keyframes spin {
	0%, 50% {
		transform: rotate(-180deg);
	}
	100% {
		transform: rotate(0deg);
	}
}