.step1 input[type=radio] {
	display: none;
}

.step1 input[type=radio] ~ label {
	cursor: pointer;
	user-select: none;
	display: block;
	padding: 10px;
	border: 2px solid #a16d5d;
}
.step1 input[type=radio]:checked ~ label {
	background: #a16d5d;
	color: white;
}


.step2 input[type=radio] {
	display: none;
}

.step2 input[type=radio] ~ label {
	cursor: pointer;
	user-select: none;
	display: block;
	padding: 10px;
	margin: 10px;
	border: 2px solid white;
	color: transparent;
}
.step2 input[type=radio]:checked ~ label {
	border: 2px dotted black;
}