.ImgSlider {
	overflow: hidden;
	width: 100%;
	position: relative;
}
.ImgSlider .off{
	display: none;
}

.ImgSlider .slide {
	position: absolute;
	top: 0px;
}
.ImgSlider .slides {
	animation: init 1000ms;
}

/* Slide animation */
.ImgSlider .slide-enter {
	transform: translateX(100%);
}

.ImgSlider .slide-enter-active {
	transform: translateX(0%);
	transition: transform 700ms ease-in-out;
}

.ImgSlider .slide-exit {
	transform: translateX(0%);
	
}
.ImgSlider .slide-exit-active {
	transform: translateX(-100%);
	transition: transform 700ms ease-in-out;
}

/* opacity animation */
.ImgSlider .opacity-enter {
	opacity: 0;
}

.ImgSlider .opacity-enter-active {
	opacity: 1;
	transition: opacity 700ms ease-in-out;
}

.ImgSlider .opacity-exit {
	opacity: 1;
	
}
.ImgSlider .opacity-exit-active {
	opacity: 0;
	transition: opacity 700ms ease-in-out;
}


@keyframes init {
	0%, 99%{
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

/* Controls */
.ImgSlider .controls {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
}

.ImgSlider .arrow {
	position: absolute;
	transform: translateY(-50%);
	border: none;
	width: 25px;
	height: 60px;
	background-color: rgba(255,255,255,0.2);
	background-size: 100% 100%;
	filter: invert(1);
	cursor: pointer;
	top: 50%;
}

.ImgSlider .arrow.left {
	left: 0px;
	background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGhlaWdodD0iNTEycHgiIGlkPSJMYXllcl8xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MTIgNTEyOyIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgd2lkdGg9IjUxMnB4IiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48cG9seWdvbiBwb2ludHM9IjM1MiwxMTUuNCAzMzEuMyw5NiAxNjAsMjU2IDMzMS4zLDQxNiAzNTIsMzk2LjcgMjAxLjUsMjU2ICIvPjwvc3ZnPg==');
}
.ImgSlider .arrow.right {
	right: 0px;
	background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGhlaWdodD0iNTEycHgiIGlkPSJMYXllcl8xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MTIgNTEyOyIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgd2lkdGg9IjUxMnB4IiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48cG9seWdvbiBwb2ludHM9IjE2MCwxMTUuNCAxODAuNyw5NiAzNTIsMjU2IDE4MC43LDQxNiAxNjAsMzk2LjcgMzEwLjUsMjU2ICIvPjwvc3ZnPg==');
}

.ImgSlider .arrow:focus, .ImgSlider .arrow:hover {
	outline: none;
	background-color: rgba(255,255,255,0.5);
}

.ImgSlider .dots {
	position: absolute;
	bottom: 1em;
	left: 50%;
	transform: translateX(-50%);
}

.ImgSlider .dots button {
	display: inline-block;
	width: 1em;
	height: 1em;
	margin: 3px;
	border: 0px solid black;
	background: black;
	border-radius: 50%;
	cursor: pointer;
	opacity: 0.6;
}
.ImgSlider .dots button:focus {
	outline: none;
}

.ImgSlider .dots button.active {
	background: white;
	opacity: 1;
}