.header {
	width: 100%;
	position: sticky;
	top: 0px;
	background: white;
	margin-bottom: 20px;
	z-index: 999;
}
.header .mainHeader {
	border-bottom: 1px solid #eaeaea;
}
.header .mainHeader.mainLine{
	width: 100%;
	height: 70px;
    text-align: center;
}
.header .logo {
	height: 50px;
	text-align: center;
	margin: 10px;
}

.header .secondLine .menuItems {
	padding: 0px;
	margin: 0px;
}
.header .secondLine .menuItem {
	font-size: 15px;
    line-height: 18px;
    padding: 22px 0;
    margin-right: 26px;
    position: relative;
    display: inline-block;
}

.header .secondLine .menuItem a {
	text-decoration: none;
	color: #2B2B2B;
}

.header .menuItem .submenu {
	display: none;
}
.header .submenu {
	padding: 25px 0px;
	max-height: 50vh;
	overflow: auto;
}

.header .submenu .title {
	text-transform: uppercase;
	color: #9f9f9f;
	margin-bottom: 15px;
	display: block;
}
.header .submenu a {
	display: block;
	text-decoration: none;
	margin: 5px 0px;
	color: #2B2B2B;
}
.header .submenu a:hover, .hoverColor:hover {
	color: #a16d5d;
}
