.like {
	cursor: pointer;
	display: block;
	color: #F78F8F;
}

.like img {
	max-height: 1.7em;
	float: right;
}
.like .count {
	float: right;
	transform: translate(-8px,0.4em);
}