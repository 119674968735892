.imageBox button {
	position: absolute;
	transform: translateY(-50%);
	border: none;
	width: 25px;
	height: 60px;
	background-color: rgba(255,255,255,0.2);
	background-size: 100% 100%;
	filter: invert(1);
	cursor: pointer;
	top: 50%;
}
.imageBox .magnifynode {
	height: 100%;
}
.imageBox .magnifynode img {
	display: block;
    margin: auto;
}

.imageBox button.left {
	left: 0px;
	background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGhlaWdodD0iNTEycHgiIGlkPSJMYXllcl8xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MTIgNTEyOyIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgd2lkdGg9IjUxMnB4IiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48cG9seWdvbiBwb2ludHM9IjM1MiwxMTUuNCAzMzEuMyw5NiAxNjAsMjU2IDMzMS4zLDQxNiAzNTIsMzk2LjcgMjAxLjUsMjU2ICIvPjwvc3ZnPg==');
}
.imageBox button.right {
	right: 0px;
	background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGhlaWdodD0iNTEycHgiIGlkPSJMYXllcl8xIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MTIgNTEyOyIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgd2lkdGg9IjUxMnB4IiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48cG9seWdvbiBwb2ludHM9IjE2MCwxMTUuNCAxODAuNyw5NiAzNTIsMjU2IDE4MC43LDQxNiAxNjAsMzk2LjcgMzEwLjUsMjU2ICIvPjwvc3ZnPg==');
}
.imageBox button:focus, .imageBox button:hover {
	outline: none;
	background-color: rgba(255,255,255,0.5);
}

.imageBox .imgLine {
	margin-top: 25px;
	white-space: nowrap;
	overflow: auto hidden;
}
.imageBox .imgLine img {
	width: 10%;
	cursor: pointer;
	opacity: 0.7;
}
.imageBox .imgLine img:hover, .imageBox .imgLine .selected {
	opacity: 1;
	transition: opacity 0.1s;
}

.imageBox .imgContainer {
	width: 100%;
	padding-top: 100%;
	position: relative;
}

.imageBox .imgWrap {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: #eee;
}

.imageBox img {
	max-width: 100%;
	max-height: 100%;
}