.notification {
	padding: 10px 20px 10px 10px;
	text-align: justify;
}
.notification a {
	color: #333333;
	text-decoration: none;
}

.notification:hover {
	background: #EAEAEA;
	cursor: pointer;
}

.notification .title {
	font-weight: bold;
	margin: 0px;
}
.notification .text {
	margin: 5px 0px;
}