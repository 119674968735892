.comment-content::before {
    content: '';
    display: block;
    width: 1em;
    height: 1em;
    background: white;
    border-bottom: 1px solid #eaeaea;
    border-left: 1px solid #eaeaea;
    border-right: none;
    border-top: none;
    position: absolute;
    transform: translate(calc(-10px - 50%),1em) rotate(45deg);
    border-radius: 5px;
}
.comment-content {
    background: white;
    padding: 10px;
    border: 1px solid #eaeaea;
    border-radius: 5px;
}