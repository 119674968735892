.Chip {
	background: #dfdfdf;
	color: black;
	border-radius: 50px;
	display: inline-block;
	position: relative;
	font-size: 0.9em;
	overflow: hidden;
	padding: 3px 13px;
	margin: 0px 5px;
}
.Chip.big {
	padding: 10px 15px;
	margin: 10px 0px;
}

.Chip img {
	height: 1.5em;
	float: left;
	margin: 0px 7px 0px 0px;
}

.Chip span {
	display: inline-block;
	margin-top: 0.2em;
}

/* Types */
.Chip.expert {
	background: #faf4f1;
	color: #c47552;
}

.Chip.trusted {
	background: #f8f6f4;
	color: #ab8d75;
}

.Chip.invisible {
	opacity: 0;
	pointer-events: none;
}

.Chip.success {
	background: #4caf50;
	color: white;
}

.Chip.warning {
	background: #ff9800;
	color: white;
}

.Chip.sold {
	background: #a16d5d;
	color: white;
	overflow: initial;
	border-radius: 0px;
}
.Chip.sold::before {
	content: '';
	display: block;
    width: 1.3em;
    height: 1.3em;
    background: #a16d5d;
	position: absolute;
	transform: translate(-50%,-50%) rotate(45deg);
	top: 50%;
	left: 0px;
}

.Chip.sold::after {
	content: '';
	display: block;
    width: 0.5em;
    height: 0.5em;
	background: white;
	border-radius: 100%;
	position: absolute;
	transform: translate(-50%,-50%);
	top: 50%;
	left: 0px;
}

.Chip.loading {
	background: repeating-linear-gradient(45deg, #dfdfdf 0px, #dfdfdf 10px, #c5c5c5 10px, #c5c5c5 20px);
	animation: chiploading 0.5s linear infinite;
	font-weight: bold;
}

@keyframes chiploading {
	0% {
		background-position-y: 0px;
	}
	100% {
		background-position-y: -29px;
	}
}